.backgroundImages {
    height: calc(100vh - 140px);
    background-image: url('../../../assets/hero.webp');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    z-index: 1;
    margin-top: 140px;
}

.backgroundImages::after {
    content: "";
    position: absolute;
    top: 0px;
    left: 0px;
    bottom: 0px;
    right: 0px;
    background-color: rgba(0, 0, 0, 0.4);
}

.backgroundImagesDark {
    height: calc(70vh - 140px);
    background-image: url('../../../assets/hero.webp');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    z-index: 1;
    margin-top: 140px;
}

.backgroundImagesDark::after {
    content: "";
    position: absolute;
    top: 0px;
    left: 0px;
    bottom: 0px;
    right: 0px;
    background-color: rgba(0, 0, 0, 0.5);
}

@media screen and (max-width: 767px) {
    .backgroundImages {
        height: calc(100vh - 100px);
        margin-top: 100px;
    }

    .backgroundImagesDark {
        height: calc(70vh - 100px);
        margin-top: 100px;
    }
}